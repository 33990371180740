<template>
  <v-card flat class="widget-event theme--light v-sheet--outlined ">
    <v-card-title class="primary--text text-bold widget-event-header"> Test Widget Event </v-card-title>
    <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light" />
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
            <div ref="myBanner">
                  <v-responsive  :aspect-ratio="16/9">
                    <v-img :src="srcValue" :lazy-src="$getOrignalImage()"  />
                  </v-responsive>
                </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="widget-event-wrapper">
            <div class="widget-event-add theme--light v-sheet--outlined">
                <vue-widget :code="code" :event-id="eventId" title="Vuidget live example"></vue-widget>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="8">
          <v-card-title class="font-weight-bold text-h5">About this Event</v-card-title>
          <v-card-text v-html="event.blurbMain"> </v-card-text>

          <v-card-title v-if="event.blurbExtra" class="font-weight-bold text-h8"
            >Important Event Information</v-card-title
          >
          <v-card-text v-if="event.blurbExtra" v-html="event.blurbExtra"> </v-card-text>

          <v-card-title v-if="event.infoMessage || event.ageRestrictions" class="font-weight-bold text-h8"
            >Age restrictions</v-card-title
          >
          <v-card-text class="font-weight-bold" v-if="event.ageRestrictions"
            >Over {{ event.ageRestrictions }}s only. ID may be required to gain entry. Failure to provide valid ID may
            result in non-admission, without eligibility for a refund.</v-card-text
          >
          <v-card-text v-if="event.infoMessage" v-html="event.infoMessage"> </v-card-text>

          <v-card-title v-if="event.timingInformation" class="font-weight-bold text-h8"
            >Timing Information</v-card-title
          >
          <v-card-text v-if="event.timingInformation" v-html="event.timingInformation"> </v-card-text>

          <v-card-title v-if="event.additionalInformation" class="font-weight-bold text-h8"
            >Event terms & conditions</v-card-title
          >
          <v-card-text v-if="event.additionalInformation" v-html="event.additionalInformation"> </v-card-text>

          <v-card-title v-if="event.venue && event.venue.information" class="font-weight-bold text-h8"
            >Venue Information</v-card-title
          >
          <v-card-text v-if="event.venue && event.venue.information" v-html="event.venue.information"> </v-card-text>

          <v-card-title>Performing:</v-card-title>
          <v-card-subtitle style="padding: 0" v-if="!isPerformerMore">
            <v-card-text>
              <span v-for="(item, index) in event.performers" :key="item._id">
                <span v-if="index < 5"> <span v-if="index != 0">,</span> {{ item.name }}</span></span
              >
              <a
                class="text-h8"
                v-if="event.performers && event.performers.length > 5"
                @click="showMore(event.performers)"
              >
                View More</a
              >
            </v-card-text>
          </v-card-subtitle>
          <v-card-subtitle style="padding: 0" v-if="isPerformerMore">
            <v-card-text>
              <span v-for="(item, index) in event.performers" :key="item._id">
                <span> <span v-if="index != 0">,</span> {{ item.name }}</span></span
              >
              <a class="text-h8" @click="showLess()"> View Less</a>
            </v-card-text>
          </v-card-subtitle>

          <v-card-subtitle class="text-bold text--primary">Ticket Price</v-card-subtitle>
          <template class="pt-8">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Ticket type
                    </th>
                    <th class="text-left">
                      Cost
                    </th>
                    <th class="text-left">
                      Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in event.ticketCategories" :key="item._id">
                    <td>{{ item.category.name }}</td>
                    <td>{{ (item.price + item.bookingFee) | currency }}</td>
                    <td>
                      <span v-if="item.availableCount <= item.purchaseCount">Sold Out</span>
                      <span cols="6" sm="3" md="2" class="px-md-0" v-else-if="checkTicketAvailabe(item)">
                        Unavailable
                      </span>
                      <span v-else>
                        Available
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>

          <v-card-subtitle v-if="event.tags && event.tags.length > 0" class="text-bold text--primary"
            >Tags:</v-card-subtitle
          >
          <v-card-text>
            <template v-for="tag in event.tags">
              <template v-if="tag">
                <v-chip :key="tag" class="rounded mr-2" color="primary" outlined> {{ tag }} </v-chip>
              </template>
            </template>
          </v-card-text>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card-title class="text-bold text-h5">Event Category</v-card-title>
          <v-card-text>
            <span v-if="event.genre && event.genre.name">{{ event.genre.name }}</span>
            <span v-if="event.subCategory && event.subCategory.name">- {{ event.subCategory.name }}</span>
          </v-card-text>

          <v-card-title class="text-bold text-h5">Date and Time</v-card-title>
          <v-card-text class="subtitle-2 d-flex flex-column align-start">
            <span>{{ event.startsAt | fullday }}</span>
            <span>{{ event.startsAt | time }} - {{ event.endsAt | time }}</span>
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <a v-bind="attrs" v-on="on"><span class="primary--text">Add to calendar</span></a>
              </template>
            </v-menu>
          </v-card-text>
          <v-card-text class="subtitle-2 d-flex flex-column align-start">
            <span>Location:</span>
            <span>{{ event.venue ? event.venue.name : '' }}</span>
            <span v-if="event.venue && event.venue.address && event.venue.address.line1">
              {{ event.venue.address.line1 }},
            </span>
            <span v-if="event.venue && event.venue.address && event.venue.address.line2">
              {{ event.venue.address.line2 }},
            </span>
            <span v-if="event.venue && event.venue.address && event.venue.address.city">
              {{ event.venue.address.city }},
            </span>
            <span v-if="event.venue && event.venue.address && event.venue.address.postcode">
              {{ event.venue.address.postcode }},
            </span>
            <span v-if="event.venue && event.venue.address && event.venue.address.country">
              {{ event.venue.address.country }}
            </span>
          </v-card-text>

          <v-card-title v-if="isShowUnavailablePage" class="text-bold text-h5"> Ticket Available Date</v-card-title>
          <v-card-text v-if="isShowUnavailablePage" class="subtitle-2 d-flex flex-column align-start">
            <span>Start Date - {{ event.availableFrom | fullDateAndTime }}</span>
            <span>End Date - {{ event.availableUntil | fullDateAndTime }}</span>
          </v-card-text>

          <v-card-title v-if="event && event.doorsAt" class="text-bold text-h5"> Door Time</v-card-title>
          <v-card-text v-if="event && event.doorsAt" class="subtitle-2 d-flex flex-column align-start">
            <span>{{ event.doorsAt | fullday }}</span>
            <span>{{ event.doorsAt | time }}</span>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { CustomWidgetService } from '@services';

export default {
  data() {
    return {
      event: {},
      loading: false,
      srcValue: '',
      isPerformerMore: false,
      isShowUnavailablePage: false,
      code: '',
      eventId: ''
    };
  },
  created() {
    this.getEvent();
  },
  methods: {
    async getEvent() {
      this.loading = true;
      this.code = this.$route.params.id;
      this.eventId = this.$route.params.eventId
      const response = await CustomWidgetService.getEventInfo(this.$route.params.id, this.$route.params.eventId);
      if (response) {
        this.event = response;
        this.setSrcValue(this.event);
      }
      this.loading = false;
    },
    setSrcValue(event) {
      setTimeout(() => {
        const clientWidth = _.get(this, '$refs.myBanner.clientWidth') || 1600;
        const width = parseInt(clientWidth * window.devicePixelRatio);
        console.log('www', width, clientWidth, this.getWidth(width), this.$refs.myBanner.clientWidth);
        this.srcValue = this.$getOrignalImageByWidth(event.image, event.imageUserId, event._id, this.getWidth(width));
      }, 200);
    },
    showMore() {
      this.isPerformerMore = true;
    },
    showLess() {
      this.isPerformerMore = false;
    },
    checkTicketAvailabe(ticket) {
      let isAvailableError = false;
      if (
        ticket.availableFrom &&
        ticket.availableUntil &&
        !moment().isBetween(moment(ticket.availableFrom), moment(ticket.availableUntil), 'days', true)
      ) {
        isAvailableError = true;
      } else if (ticket.availableFrom && moment().isBefore(moment(ticket.availableFrom))) {
        isAvailableError = true;
      } else if (ticket.availableUntil && moment().isAfter(moment(ticket.availableUntil))) {
        isAvailableError = true;
      }
      if (this.isShowUnavailablePage) {
        isAvailableError = true;
      }
      return isAvailableError;
    },
    getWidth(width) {
      if (width <= 100) {
        return 100;
      } else if (width <= 400) {
        return 400;
      } else if (width <= 800) {
        return 800;
      } else if (width <= 1200) {
        return 1200;
      } else if (width <= 1600) {
        return 1600;
      } else if (width <= 2000) {
        return 2000;
      } else {
        return 2000;
      }
    },
  },
};
</script>
